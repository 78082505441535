<template>
  <v-app>
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col>
          <v-card class="ml-2">
            <v-card-title>{{inOutdata.jsName}}-{{inOutdata.ioName}}{{inOutdata.inorout === 'I' ? "入" : "出"}}場<v-btn @click="prepareSpeak('語音開啟')" class="ml-10 mb-4" color="success" style="font-size: 16px;font-weight: bolder;" v-if="checkPlusFunc('voiceAlarmEnable')">開啟語音</v-btn></v-card-title>
           
            <v-container style="height: 50px;" v-if="!progressFlag">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col
                  class="subtitle-1 text-center"
                  cols="12"
                >
                  <span style="color:red;font-size:40px;font-weight:bold;">辨識中...</span>
                </v-col>
                <v-col cols="12">
                  <v-progress-linear
                    color="green darken-3"
                    indeterminate
                    rounded
                    height="10"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
            <div id="video-background" style="text-align: center;">
            <canvas id="canvasV" :width="convasWidth" :height="convasHeight" class="mirrorRotateLevel"/>
            <video
                ref="video"
                id="video"
                autoplay
                width="40%"
                class="mirrorRotateLevel"
                style="margin-left: 5000px;"
            />
            <img :src="base64Str" name="codeImage" id="codeImage" style="position: fixed;"/>
            </div>
            <canvas v-show="false"  id="canvas1" :width="outWidth" :height="outHeight"/>
            <v-btn @click="resetTracking" v-if="false">重啟</v-btn>       
            <a id="voiceBtn" @click="testSpeak" v-show="false">測試語音</a>
            <v-img
              max-height="150"
              max-width="250"
              :src="lightUrl"
            ></v-img>
            
          </v-card>                            
        </v-col>
    </v-row>
    <br/>
    </v-container>
    <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
  </v-app>
</template>

<script>
import loadingMask from '@/components/public/loadingMask.vue'
import commonMixin from '@/utils/commonMixin';
import { BrowserMultiFormatReader } from "@zxing/library";
import Speech from "speak-tts";
//import {inOut, getJobSiteData, getSysParamListByCode, checkInOut} from "@/api/hyirecoApi";
import {inOut, getJobSiteForInOut, onlineAddMember, getSysParamListByCode, checkInOut} from "@/api/hyirecoApi";

require('tracking/build/tracking-min.js')
require('tracking/build/data/face-min.js')

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
    //this.authValidate(); //權限檢查

    this.auth = {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
      }
    };

    this.authWithTimeout = {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
      },
      timeout: 6000
    };
    
    this.getMemberProperty(); //取得人員屬性
    this.inOutdata = JSON.parse(this.$route.params.data);
    this.getData(); //取得入出資料
  },

  destroyed() {
    console.log("destroyed");
    this.closeCamera();
    clearInterval(this.inoutTime);
    this.inoutTime = null;
    clearInterval(this.refrashAuth);
    this.refrashAuth = null;
  },
  mounted(){
    var vm = this;
    vm.codeReader =  new BrowserMultiFormatReader();
    vm.userName = vm.loginAuth.memberName;
    vm.initTracking();
    
    vm.refrashAuth = setInterval(() => {
      this.auth = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        }
      };

      this.authWithTimeout = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getTokenWithSession(),
        },
        timeout: 6000
      };
    },1000);

    vm.inoutTime = setInterval(() => {
      vm.resetCountdown();
    },1500000);

    if (window.innerWidth > window.innerHeight) { // 判斷畫面橫式截圖尺寸改為橫式
      var tmp = vm.convasWidth;
      vm.convasWidth = vm.convasHeight;
      vm.convasHeight = tmp;

      var tmp2 = vm.outWidth;
      vm.outWidth = vm.outHeight;
      vm.outHeight = tmp2;
    }

    vm.speech = new Speech();
    vm.speech.init().then(() => {
      if (vm.checkPlusFunc('voiceAlarmEnable')){
        //vm.prepareSpeak("辨識開始");
        //console.log(vm.$refs.voiceBtn);
        //vm.$refs.voiceBtn.click();
        //document.getElementById("voiceBtn").click();
      }
      vm.openCamera();
    }).catch(e => {
      console.error("An error occured while initializing : ", e);
    });
  },
  methods: {
    testSpeak: function(){
      this.prepareSpeak('辨識開始');
    },
    prepareSpeak: function(text) { //語音
      let vm = this;
      console.log("prepareSpeak:" + text);
      vm.speech.setLanguage("zh-TW");
      vm.speech.speak({
        text: text
      })
      .then((data) => {
        console.log("Success !", data);
      })
      .catch((e) => {
        console.error("An error occurred :", e);
      });
    },
    startFrontCamera () {
      this.camera = 'front';
    },
    onCameraChange (promise) {
      promise.catch(error => {
        const cameraMissingError = error.name === 'OverconstrainedError'
        const triedFrontCamera = this.camera === 'front'

        if (triedFrontCamera && cameraMissingError) {
          // no front camera on this device
        }
      })
    },
    getMemberProperty: function(){
      var vm = this;
      getSysParamListByCode("people",vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          if (responseData.statusCode === "1") {
            responseData.sysParam.childParamList.forEach(function(para){
              var tmpArr =  para.childParamList.map(function(child){
                              var item = new Object();
                              item.text = para.name + "-" + child.name;
                              item.value = child.code;
                              return item;
                            });
              //console.log("tmpArr = " + JSON.stringify(tmpArr));
              vm.memberPropertyList = vm.memberPropertyList.concat(tmpArr);
            });
          }
        }
      });
    },
    getData: function(){
      this.isLoading = true;
      var vm = this;
      getJobSiteForInOut(this.inOutdata.jsId, vm.auth).then(function(response) {
          if ("200" == response.status) { 
              var responseData = response.data;
              vm.inOutdata.orgCode = responseData.jobSite.orgnization.orgCode;
              vm.inOutdata.activity = responseData.jobSite.activity;
              vm.inOutdata.notMemberPrompt = responseData.jobSite.notMemberPrompt;
              vm.inOutdata.orgId = responseData.jobSite.orgId;
              vm.inOutdata.jsName =  responseData.jobSite.jsName;
              //console.log("vm.inOutdata = " + JSON.stringify(vm.inOutdata));
          }
      });
      this.isLoading = false;
    },
    resetTracking() {
      this.flag = true
      this.progressFlag = true
      this.base64Str = ''
      this.trackerTask = ''
      this.tracker = {}
      this.mediaStreamTrack = {}
      this.video = {}
      this.count = 0
      this.initTracking()
    },
    initTracking() {
      const vm = this;
      const video = this.video = document.getElementById('video');
      vm.tracker = new window.tracking.ObjectTracker(['face']);
      vm.tracker.setInitialScale(4);
      vm.tracker.setStepSize(2);
      vm.tracker.setEdgesDensity(0.1);
      vm.openCamera();
      
      vm.trackerTask = window.tracking.track('#video', vm.tracker);

      vm.tracker.on('track', function(event) {
        
        if (vm.flag) {
          const canvasV = document.getElementById('canvasV');
          const contextV = canvasV.getContext('2d');
          contextV.drawImage(video, 0, 0,vm.convasWidth, vm.convasHeight);

          if (event.data.length > 0 ) {
            const canvasUpload = document.getElementById('canvas1');
            const contextUpload = canvasUpload.getContext('2d');

            event.data.forEach(function (rect) {
                if(rect.height >= 100) {
                  vm.faceCount++;
                  contextUpload.drawImage(canvasV, 0, 0, vm.outWidth, vm.outHeight);
                  
                }
            });

            if (vm.faceCount >=3) {
              vm.flag = false;
              vm.progressFlag = false;
              
              // 人脸 base64
              vm.base64Str = canvasUpload.toDataURL('image/jpeg');
              vm.inOutdata.recognizeString = vm.base64Str;
              vm.inOutdata.mode = "R";
              vm.onChecked();
            }
          } else {
            console.log("no face");
            vm.faceValidateFaild++;

            if (vm.faceValidateFaild >= 10) {
              vm.faceCount = 0;
              vm.faceValidateFaild = 0;
            }
            const canvasUpload = document.getElementById('canvas1');
            const contextUpload = canvasUpload.getContext('2d');
            contextUpload.drawImage(canvasV, 0, 0, vm.outWidth, vm.outHeight);
            vm.base64Str = canvasUpload.toDataURL('image/jpeg');
            vm.codePocessFile();
          }
        }
      })
    },
    openCamera() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          const getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia

          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia 瀏覽器不支援'))
          }

          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      const constraints = {
        video: true,
        audio: false
      }
      const promise = navigator.mediaDevices.getUserMedia(constraints)
      promise.then(stream => {
        this.mediaStreamTrack = stream.getTracks()[0]
        window.stream = stream
        const video = document.getElementById('video')
        if ('srcObject' in video) {
          video.srcObject = stream
        } else {
          video.src = window.URL.createObjectURL(stream)
        }
        video.onloadedmetadata = function() {
          video.play()
        }
      }).catch(err => {
        console.error(err.name + ': ' + err.message)
        console.error('無權限')
      })
    },
    closeCamera() {
      if (typeof window.stream === 'object') {
        if ('srcObject' in this.video) {
          this.video.srcObject = null
        }
        window.stream.getTracks().forEach(track => track.stop())
      }
    },
    onDecode (result) {
      //this.result = result
      console.log("result = " + result );
      if (result != "") {
        this.progressFlag = false;

        this.inOutdata.recognizeString = result;
        this.inOutdata.mode = "Q";
        this.onChecked();

        this.faceCount = 0;
        this.faceValidateFaild = 0;
        
      }
    },
    onChecked: function () {
      var vm = this;
      //console.log("inOutdata = " + JSON.stringify(vm.inOutdata));

      inOut(vm.inOutdata, vm.authWithTimeout).then(function(response) {
          if ("200" == response.status) { 
            var responseData = response.data;
            var voiceText = "辨識成功";
            console.log("responseData = " + JSON.stringify(response));
            console.log("responseData.statusCode = " + responseData.statusCode);
            vm.progressFlag = true;

            if (responseData.statusCode === "1" || responseData.statusCode === "3") {
              vm.memberName = responseData.orgnizationMember.name;
              vm.memberPhone = responseData.orgnizationMember.mobilePhone;
              vm.dataPropertyList = responseData.orgnizationMember.memberPropertyList;
              vm.keepWorkdays = responseData.orgnizationMember.keepWorkdays;
              
              //console.log("vm.dataPropertyList = " + vm.dataPropertyList);
            
              var filterEmpty = vm.memberPropertyList.filter(function (item) {
                return vm.dataPropertyList.some(function (item2) {
                  return item2 === item.value;
                });
              });

              //console.log("filterEmpty = " + JSON.stringify(filterEmpty));
              var notSiteMemberHtml = "";
              if (responseData.statusCode === "3") {
                notSiteMemberHtml = `<div style="font-size:55px;font-weight:bold;color:red;">非場域(活動)人員</div>`;
              }

              var pHtmlStr = "";
              filterEmpty.forEach(function(item){
                pHtmlStr += '<span class="ma-2 v-chip theme--light white--text v-size--x-large" style="background-color: #3f51b5!important;border-color: #3f51b5!important;color: #fff!important;"><span class="v-chip__content">'+ item.text +'</span></span>';
              });

              var keepWorkdaysHtml = "";
              //vm.keepWorkdays = "7";
              if (parseInt(vm.keepWorkdays) >= 7 && this.checkPlusFunc('keepworkAlertEnable')) {
                keepWorkdaysHtml = `<div style="font-size:55px;font-weight:bold;color:red;">已連續` + vm.keepWorkdays + `天入出場</div>`;
                voiceText += ",已連續" + vm.keepWorkdays + "天入出場";
              }

              if (vm.inOutdata.activity === "Y") {
                vm.inOutdata.orgmId = responseData.orgnizationMember.orgmId;
                var btnTxt = vm.inOutdata.inorout === "I" ? "報到" : "簽退"
                vm.$swal({
                  title: `<span style="color:green;font-size:40px;">辨識成功</span>`,
                  html: `<svg style="width:100px;height:100px;color: green;" viewBox="0 0 24 24" v-if="isPass === 'Y'">
                    <path fill="currentColor" d="M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                  <div style="font-size:120px;font-weight:bold;">`+ vm.memberName +`</div>`+ pHtmlStr + keepWorkdaysHtml + notSiteMemberHtml,
                  showCancelButton: true,
                  confirmButtonText: btnTxt,
                  cancelButtonText: "<font face='Microsoft JhengHei' color='#fff'>取消</font>",
                }).then((result) => {
                  console.log(result);
                  if (result.isConfirmed) {
                    vm.onSignIn();
                  } else { 
                    setTimeout(() => {
                      vm.checkCount = 0;
                      vm.flag = true; 
                    }, 500);
                  }
                });

                if (vm.checkPlusFunc('voiceAlarmEnable')){
                  vm.prepareSpeak(voiceText);
                }

              } else {
                vm.$swal({
                  title: `<span style="color:green;font-size:40px;">辨識成功</span>`,
                  html: `<svg style="width:100px;height:100px;color: green;" viewBox="0 0 24 24" v-if="isPass === 'Y'">
                    <path fill="currentColor" d="M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                  <div style="font-size:120px;font-weight:bold;">`+ vm.memberName +`</div>` + pHtmlStr + keepWorkdaysHtml + notSiteMemberHtml,
                  timer: 3000,
                  showConfirmButton: false
                });

                //console.log("vm.loginAuth.orgnization.smartPlugEnable = " + vm.loginAuth.orgnization.smartPlugEnable);
                //console.log("vm.loginAuth.orgnization = " + vm.loginAuth.orgnization);
                if (typeof vm.loginAuth.orgnization !== "undefined") {
                  if (vm.checkPlusFunc('smartPlugEnable')){
                    console.log("smartPlugSuccessUrl");
                    vm.onSmartPlug(vm.loginAuth.orgnization.smartPlugSuccessUrl); //智慧插座
                  }
                }

                if (vm.checkPlusFunc('voiceAlarmEnable')){
                  vm.prepareSpeak(voiceText);
                }

                setTimeout(() => {
                  vm.checkCount = 0;
                  vm.flag = true; 
                  vm.memberName = "";
                  vm.memberPhone = "";
                  vm.dataPropertyList = "";
                  vm.faceCount = 0;
                  vm.faceValidateFaild = 0;
                }, 4200);
              }

            } else if (responseData.statusCode === "4"){
                //vm.closeCamera();

                var faceList = responseData.detectRecord.faceList;
                var swalHtml = '';
                
                faceList.forEach(function(item){
                  swalHtml += `<div id="`+ item.similarMemberPhone +`" class="v-card v-card--link v-sheet v-sheet--outlined theme--light elevation-2" style="background-color: #C5E1A5;color: rgba(0, 0, 0, 0.87);box-shadow: 3px 3px 3px 3px #cccccc;margin-bottom: 10px;"><div data-v-b93c2e4e="" class="v-card__title" style="font-size: 55px;font-weight: bolder;margin-top: 10px;justify-content: center;">` + item.similarMemberName + `</div><div data-v-b93c2e4e="" class="v-card__subtitle" style="text-align: left;font-size: 28px;margin-top: 1px;font-weight: bolder;text-align: center;">`+ item.similarMemberPhone +`</div></div>`;
                });

                vm.$swal({
                  title: `<span style="color:red;font-size:40px;">請問您是哪位??請點選</span>`,
                  html: swalHtml,
                  confirmButtonText: "<font style='font-size: 30px;font-weight: bolder;'>都不是</font>",
                  focusConfirm: false,
                }).then(() => {
                  //console.log(result);
                  // if (result.isConfirmed) {
                    
                  // } 
                  vm.confirmInoutData("請輸入您的個人資料");
                });

                faceList.forEach(function(item){
                  document.getElementById(item.similarMemberPhone).onclick = function(){
                      vm.updateInoutData(item.similarMemberName ,item.similarMemberPhone, item.drId);
                  };
                });
            } else if (responseData.statusCode === "2"){
              //vm.closeCamera();
              vm.confirmInoutData("辨識失敗，請輸入您的個人資料");
            }
          }
      }).catch(() => {
        vm.progressFlag = true;
        vm.$swal({
          title: `目前網路環境不佳，請確認網路狀態，再重新辨識!`,
          type: 'error',
          focusConfirm: false,
        }).then(() => {
          vm.checkCount = 0;
          vm.flag = true; 
          vm.memberName = "";
          vm.memberPhone = "";
          vm.dataPropertyList = "";
          vm.faceCount = 0;
          vm.faceValidateFaild = 0;
        });
        
      });
    },
    updateInoutData: function(name, phone, drId){
      var vm = this;
      vm.$swal({
        title: "資料處理中請稍候",
        showConfirmButton: false
      });

      vm.inOutdata.photo_base64 = vm.inOutdata.recognizeString;
      vm.inOutdata.recognizeString = "";
      vm.inOutdata.name = name;
      vm.inOutdata.phone = phone;
      vm.inOutdata.drId = drId;

      //console.log("vm.inOutdata = " + JSON.stringify(vm.inOutdata) );
      onlineAddMember(vm.inOutdata, vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          //vm.onSmartPlug(vm.loginAuth.orgnization.smartPlugSuccessUrl); //智慧插座
          //console.log("responseData = " + JSON.stringify(responseData));

          if (typeof vm.loginAuth.orgnization !== "undefined") {
            if (vm.checkPlusFunc('smartPlugEnable')){
              console.log("smartPlugSuccessUrl");
              vm.onSmartPlug(vm.loginAuth.orgnization.smartPlugSuccessUrl); //智慧插座
            }
          }

          if (vm.checkPlusFunc('voiceAlarmEnable')){
            vm.prepareSpeak("辨識成功");
          }

          vm.$swal({
            title: responseData.message,
            timer: 3000,
            showConfirmButton: false
          });
        }
        setTimeout(() => {
          vm.checkCount = 0;
          vm.flag = true; 
          vm.faceCount = 0;
          vm.faceValidateFaild = 0;
          //vm.openCamera(); 
        }, 3200);
      });
    },
    confirmInoutData: function(message){
      var vm = this;
      vm.$swal({
        position: 'top',
        title: `<span style="color:red;font-size:40px;">`+ message +`</span>`,
        html: `
        <s style="width:100px;height:100px;color: #c00;" viewBox="0 0 24 24" v-if="isPass === 'N'">
        <path fill="currentColor" d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14Z" tabindex="0"/>
        </s>
        <input type="text" id="name" class="swal2-input" style="font-size:30px;font-weight:bold;margin-bottom: 0px;" placeholder="姓名" >
        <input type="text" id="phone" class="swal2-input" style="font-size:30px;font-weight:bold;" placeholder="電話">
        `,
        showCancelButton: true,
        confirmButtonText: "<font style='font-size: 30px;font-weight: bolder;'>送出</font>",
        cancelButtonText: "<font color='#fff' style='font-size: 30px;font-weight: bolder;'>離開</font>",
        timer: 60000,
        focusConfirm: false,
        preConfirm: () => {
          const name = vm.$swal.getPopup().querySelector('#name').value
          const phone = vm.$swal.getPopup().querySelector('#phone').value
          if (!name || !phone) {
            vm.$swal.showValidationMessage(`請輸入姓名和電話`)
          }

          return { name: name, phone: phone }
        },
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          vm.updateInoutData(result.value.name, result.value.phone);
        } else { 
          setTimeout(() => {
            vm.checkCount = 0;
            vm.flag = true; 
            vm.faceCount = 0;
            vm.faceValidateFaild = 0;
          }, 100);
          vm.openCamera(); 
        }
      });
    },
    onSignIn: function() {
      //this.flag = true;

      this.isLoading = true;
      var vm = this;
      checkInOut(vm.inOutdata, vm.auth).then(function(response) {
          if ("200" == response.status) { 
              var responseData = response.data;
              //console.log("vm.responseData = " + JSON.stringify(responseData));
              vm.$swal({html:responseData.message,timer: 2000});
              setTimeout(() => {
                vm.checkCount = 0;
                vm.flag = true;
              }, 2500);
          }
      });
      this.isLoading = false;
    },
    onSmartPlug: function(url){ 
      // callSmartPlug(url).then(function(response) {
      //   console.log(JSON.stringify(response));
      // });
      if (this.checkPlusFunc('smartPlugEnable')) {
        var sec = 2500;
        var tmpArr = url.split("/").reverse();
        console.log(tmpArr);
        if (tmpArr.length > 2){
          if (parseFloat(tmpArr[1]).toString() !== "NaN") {
            sec = parseFloat(tmpArr[1]) * 1000 * 1.5;
          }
        }

        //console.log("sec = " + sec);

        this.lightUrl = url;
        setTimeout(() => {
          this.lightUrl = "#";
        }, sec);         
      } 
    },
    codePocessFile() {
      console.log("codePocessFile");
      this.flag = false;

      this.codeReader
      .decodeFromImage("codeImage")
      .then(result => {
        console.log(result.text);
        this.onDecode(result.text);
      })
      .catch(error => {
        console.log(error);
        this.onerror(error);
      });
    },
    onerror() {
      //console.log(err);
      this.flag = true;
    }
  },
  watch: {
    selectMember: function(value) {
      console.log(value);
    },
  },
  data: () => ({
    auth: {},
    authWithTimeout: {},
    userName: "",
    memberName: "",
    memberPhone: "",
    selectMember: "",
    isLoading: false, //loading畫面的開關
    trackerTask: '',
    tracker: {},
    mediaStreamTrack: {},
    video: {},
    inOutdata:{},
    count: 0,
    flag: true,
    progressFlag: true,
    base64Str: '',
    result: '',
    checkCount: 0,
    lastPhoto: null,
    memberPropertyList: [],
    dataPropertyList: [],
    inoutTime: null,
    camera: null,
    faceFlag: true,
    faceCount: 0,
    faceValidateFaild: 0,
    convasWidth: 550,
    convasHeight: 735,
    outWidth: 300,
    outHeight: 400,
    keepWorkdays: "0",
    lightUrl: "",
    speech: null,
    codeReader: null,
    imgBase64: null,
  }),
  components: {
    loadingMask,
  },
}
</script>

<style scoped>
.bk-radius {
    width: 200px;
    height: 200px;
    overflow: hidden;
    background-color: #42b983;
    border-radius: 50%;
    margin: auto
  }

  .video-camera {
    width: 300px;
    height: 300px;
    margin-top: -40px;
    margin-left: -40px;
  }

  .custom-block.tip {
    background-color: #ecf8ff;
    border-color: #50bfff;
    color: #409EFF;
  }

  .custom-block, .custom-block.tip, .custom-block {
    padding: .1rem 1.5rem;
    border-left-width: .5rem;
    border-left-style: solid;
    margin: 1rem 0;
  }

  .mirrorRotateLevel {          /* 水平镜像翻转 */
      -moz-transform:scaleX(-1);
      -webkit-transform:scaleX(-1);
      -o-transform:scaleX(-1);
      transform:scaleX(-1);
      /*兼容IE*/
      filter:FlipH;
  }
  .mirrorRotateVertical {        /* 垂直镜像翻转 */
      -moz-transform:scaleY(-1);
      -webkit-transform:scaleY(-1);
      -o-transform:scaleY(-1);
      transform:scaleY(-1);
      /*兼容IE*/
      filter:FlipV;
  }

  button > .swal2-styled {
    width: 40%;
  }
</style>